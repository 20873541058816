import styled from "styled-components";

export default styled.span`
  margin: 0;

  svg {
    .st0, .st1, .st2, .st3 {
      stroke: #000;
    }
  }
`;