import React from "react";
import styled from "styled-components";
import TwitterIcon from "-!svg-react-loader!../../images/twitter.svg";
import FacebookIcon from "-!svg-react-loader!../../images/facebook.svg";
import InstagramIcon from "-!svg-react-loader!../../images/instagram.svg";

const SocialsList = styled.ul`
    list-style-type: none;
    margin-left: auto;
    padding-left: 0;
    display: inline-flex;
    align-items: center;
    justify-content: right;
    flex: 0 1 content;
    li {
        display: inline-block;
        flex: 0 1 content;
        margin-left: 20px;
        svg {
            height: 40px;
        }
    }
`;

export default () => (
    <SocialsList>
        <li className="icon-twitter">
            <a href="https://twitter.com/astitchintimecc" title="twitter">
                <TwitterIcon />
            </a>
        </li>
        <li className="icon-facebook">
            <a href="https://fb.me/astitchintimecc" title="facebook">
                <FacebookIcon />
            </a>
        </li>
        <li className="icon-instagram">
            <a href="https://www.instagram.com/astitchintimecc" title="instagram">
                <InstagramIcon />
            </a>
        </li>
    </SocialsList>
);