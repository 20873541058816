import React from "react";

const Subheader = ({ title, subtitle }) => (
    <div className="subheader">
        <div className="inner">
            <h1 className="title">{title}</h1>
            <div className="text">{subtitle}</div>
        </div>
    </div>
);

export default Subheader;