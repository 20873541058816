import React, { Component } from "react";
import { Link } from "gatsby";
import Socials from "../Icons/socials";

class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <div className="inner">
          <div>
            <Link to="/privacy">Privacy policy</Link>
            <span>Contact: <a href="mailto:info@astitchintime.cc">info@astitchintime.cc</a></span>
          </div>
          <Socials />
        </div>
      </footer>
    )
  }
}
export default Footer;
