import React, { Component } from "react";
import Helmet from "react-helmet";
import firebase from "gatsby-plugin-firebase";
import Header from "./header";
import Footer from "./footer";
import styled from "styled-components";
import OriginalLogo from "-!svg-react-loader!../../images/logo_stitch.svg";
import Logo from "../Icons/logo";
import Subheader from "./subheader";
import "./layout.css";
import SEO from "../seo";

const LogoWrapper = styled.div`
  text-align: center;
  padding-top: 1em;
  margin-bottom: 5em;
  grid-area: header;
`;

class layout extends Component {
  state = {
    smallScreen: false,
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    firebase.analytics();
  }

  resize() {
    this.setState({ smallScreen: window.innerWidth <= 840 })
  }
  render() {
    const { children, page, useSubheader, subheaderContent } = this.props;
    return (
      <>
      <Helmet>
        <link rel="apple-touch-icon-precomposed" sizes="57x57" href="apple-touch-icon-57x57.png" />
        <link rel="apple-touch-icon-precomposed" sizes="114x114" href="apple-touch-icon-114x114.png" />
        <link rel="apple-touch-icon-precomposed" sizes="72x72" href="apple-touch-icon-72x72.png" />
        <link rel="apple-touch-icon-precomposed" sizes="144x144" href="apple-touch-icon-144x144.png" />
        <link rel="apple-touch-icon-precomposed" sizes="60x60" href="apple-touch-icon-60x60.png" />
        <link rel="apple-touch-icon-precomposed" sizes="120x120" href="apple-touch-icon-120x120.png" />
        <link rel="apple-touch-icon-precomposed" sizes="76x76" href="apple-touch-icon-76x76.png" />
        <link rel="apple-touch-icon-precomposed" sizes="152x152" href="apple-touch-icon-152x152.png" />
        <link rel="icon" type="image/x-icon" href="favicon.ico" />
        <link rel="icon" type="image/png" href="favicon-196x196.png" sizes="196x196" />
        <link rel="icon" type="image/png" href="favicon-96x96.png" sizes="96x96" />
        <link rel="icon" type="image/png" href="favicon-32x32.png" sizes="32x32" />
        <link rel="icon" type="image/png" href="favicon-16x16.png" sizes="16x16" />
        <link rel="icon" type="image/png" href="favicon-128.png" sizes="128x128" />
        <meta name="application-name" content="A Stitch In Time"/>
        <meta name="msapplication-TileColor" content="#FFFFFF" />
        <meta name="msapplication-TileImage" content="mstile-144x144.png" />
        <meta name="msapplication-square70x70logo" content="mstile-70x70.png" />
        <meta name="msapplication-square150x150logo" content="mstile-150x150.png" />
        <meta name="msapplication-wide310x150logo" content="mstile-310x150.png" />
        <meta name="msapplication-square310x310logo" content="mstile-310x310.png" />
        <link rel="dns-prefetch" href="https://firebaseinstallations.googleapis.com" />
        <link rel="dns-prefetch" href="https://www.googletagmanager.com" />
      </Helmet>
      <SEO />
      <div className="wrapper">
        <Header page={page} smallScreen={this.state.smallScreen} />
        {
          this.state.smallScreen
            && (
              <LogoWrapper>
                <Logo><OriginalLogo alt="A Stitch In Time" className="logo" /></Logo>
              </LogoWrapper>
            )
        }
        {useSubheader && <Subheader {...subheaderContent} />}
        <main>
          <div className="inner">{children}</div>
        </main>
        <Footer />
      </div>
      </>
    )
  }
}

export default layout
