import React, { Component } from "react";
import { Link } from "gatsby";
import OriginalLogo from "-!svg-react-loader!../../images/logo_stitch.svg";
import Logo from "../Icons/logo";
import Socials from "../Icons/socials";
import MenuIcon from "-!svg-react-loader!../../images/menu.svg";

class Header extends Component {
  openSidebar() {
    document.getElementById("sidebar").style.display = "block"
  }

  closeSidebar() {
    document.getElementById("sidebar").style.display = "none"
  }
  render() {
    return this.props.smallScreen ? (
      <div className="sidebar">
        <button onClick={this.openSidebar} className="sidebar-button-off">
          <MenuIcon className="menu-icon" />
        </button>
        <div
          className="sidebar-inner"
          style={{ display: "none" }}
          id="sidebar"
          role="menu"
          tabIndex={0}
          onClick={this.closeSidebar}
          onKeyPress={this.closeSidebar}
        >
          <button className="sidebar-button-on">
            <MenuIcon className="menu-icon" />
          </button>
          <Link to="/" className="sidebar-link">
            <button className="sidebar-item">Home</button>
          </Link>
          <Link to="/designs" className="sidebar-link">
            <button className="sidebar-item">Designs</button>
          </Link>
          <Link to="/connections" className="sidebar-link">
            <button className="sidebar-item">Connections</button>
          </Link>
          <Link to="/about" className="sidebar-link">
            <button className="sidebar-item">About</button>
          </Link>
        </div>
      </div>
    ) : (
      <header className="header">
        <div className="inner">
          <div className="logo-container">
            <Link to="/">
              <Logo><OriginalLogo className="logo" /></Logo>
            </Link>
          </div>
          <nav>
            <Link to="/">
              <button className="button">
                Home
              </button>
            </Link>
            <Link to="/designs">
              <button className="button">
                Designs
              </button>
            </Link>
            <Link to="/connections">
              <button className="button">
                Connections
              </button>
            </Link>
            <Link to="/about">
              <button className="button">
                About
              </button>
            </Link>
          </nav>
          <Socials />
        </div>
      </header>
    )
  }
}
export default Header
